.LockScreen {
  /* background-image: url("/images/BackgroundImages/Disco-Dingo_WP_4096x2304.jpg"); */
  /* background-position: center;
  background-size: 100%;
  background: linear-gradient(
    45deg,
    #dd4814,
    #77216f,
    #5e2750,
    #2c001e,
    #aea79f,
    #333333
  );*/
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.LockscreenCallToClick {
  text-align: center;
  color: white;
}
.blinking {
  animation: blink 1.5s infinite;
}
@keyframes blink {
  0% {
    color: rgba(255, 255, 255, 1);
  }
  50% {
    color: rgba(255, 255, 255, 0.5);
  }
  100% {
    color: rgba(255, 255, 255, 1);
  }
}
