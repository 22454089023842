.appWindow {
  width: 60vw;
  height: 80vh;
  display: flex;
  flex-direction: row;
  margin-left: 30%;
  margin-top: 5%;
  color: #333;
  /* top bar: B292C */
  /* highlight : ED5226 */
  /* background-color: #F5F6F8; */
}
.appWindowHidden {
  display: none;
}
.windowTopBar {
  color: whitesmoke;
  background-color: #2b292c;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.windowSideBar {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 30%;
}
.mainWindow::-webkit-scrollbar {
  width: 0.25em;
}

.mainWindow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.mainWindow::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.mainWindow {
  display: flex;
  flex-direction: column;
  background-color: #f5f6f8;
  overflow: auto;
  width: 100%;
}
