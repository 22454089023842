.WebExpBody {
  text-align: left;
  margin: 5% 10% 5% 10%;
}
.ExpCard a {
  color: #f08763 !important;
  text-decoration: none;
}
.ExpCard {
  display: flex;
  flex-direction: row;
}
.ExpImage {
  display: flex;
  flex-direction: column;
  flex: 2;
  align-self: flex-start;
}
.ExpImage img {
  /* max-height: auto;
  max-width: 100%; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  /* width: 60%; */
  /* max-width: 200px; */
}
.ExpContent {
  flex: 10;
  flex-direction: column;
  padding-left: 10px;
}

.techbadge {
  height: 32px;
  width: 32px;
}
