.topBar {
  display: inline-flex;
  flex-direction: row;
  /* background-color: #1d1d1d; */
  background-color: #1e1e1e;
  color: whitesmoke;
  width: 100%;
  justify-content: space-between;
}
.leftBarContent {
  padding: 5px 10px;
  order: 1;
}
.centeredBarContent {
  padding: 5px 10px;
  order: 2;
}
.rightBarContent {
  order: 3;
  padding: 5px 10px;
}
.rightBarContent a{
  font-weight: lighter;
  padding-left: 10px;
  filter: brightness(0) invert(1);  
}
.hamburgerIcon {
  display: none;
}
@media only screen and (max-width: 600px) {
  .hamburgerIcon {
    /* display: inline-flex; */
  }
}
