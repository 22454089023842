.ContactBody {
  text-align: left;
  margin: 5% 10% 5% 10%;  
  /* padding: 10px; */
}
h4{
  font-style: normal;
  font-weight: normal;
}
.ContactBody a {
  text-decoration: none;
}
.ContactBody i{
  margin-right: 10px;
}