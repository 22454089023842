.appIcon {
  /* max-width: 100%; */
  height: auto;
  width: 42px;
  /* height: 42px; */
  margin: 3px;
}

.appIconContainer {
  margin: 2px;
  border-radius: 5%;
}

.appIconContainer:hover {
  background-color: gray;
}

.lastAppIcon {
  margin-top: auto;
}

.lastAppIcon img {
  filter: brightness(0) invert(1);
}
.selectedNav {
  border-right: 1.5px solid #e95420;
}

.tooltip {
  /* display:inline-block; */
  position: relative;
  /* border-bottom:1px dotted #666; */
  /* text-align:left; */
}
.tooltip .right {
  min-width: 0px;
  top: 50%;
  left: 100%;
  margin-left: 10px;
  transform: translate(0, -50%);
  padding: 5px 10px;
  color: #ffffff;
  background-color: black;
  /* font-weight: normal; */
  font-size: 15px;
  border-radius: 4px;
  position: absolute;
  z-index: 99999999;
  /* box-sizing: border-box; */
  border: 0.5px solid gray;
  /* box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5); */
  visibility: hidden;
  /* opacity: 0; */
  transition: opacity 1s;
}

.tooltip:hover .right {
  visibility: visible;
  opacity: 1;
}

.tooltip .right i {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -0px;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.tooltip .right i::after {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  left: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: #222222;
  border: 1px solid #222222;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
